import React from 'react';
import { useParams } from 'react-router-dom';

import Seo from '../helpers/Seo';
import PageTitle from '../components/PageTitle';
import ProductListing from '../components/ProductListing';

const Category = () => {
    let { id, name } = useParams();
    
    return (
        <>
            <Seo title={name} />
            <PageTitle>{name}</PageTitle>
            <ProductListing category_id={id}></ProductListing>
        </>
    )
};

export default Category;