import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { createBrowserHistory } from 'history';

import { 
    BrowserRouter,
    Route,
    Switch
} from 'react-router-dom';

import theme from './helpers/theme';
import { useSessionStorage } from './helpers/useBrowserStorage';
import AppLanguage from './helpers/languages';
import AppStrings from './helpers/localizations';
import CartContext from './helpers/CartContext';
import Seo from './helpers/Seo';
import GlobalStyle from './helpers/GlobalStyle';
import LocalizedRouter from './components/LocalizedRouter';
import ProductListing from './components/ProductListing';
import Navigation from './components/Navigation';
import Header from './components/Header';
import Footer from './components/Footer';
import PageTitle from './components/PageTitle';

import Category from './pages/Category';
import Search from './pages/Search';
import Product from './pages/Product';
import Cart from './pages/Cart';
import Contacts from './pages/Contacts';
import About from './pages/About';
import CartSuccess from './pages/CartSuccess';
import ContactSuccess from './pages/ContactSuccess';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Copyright from './pages/Copyright';

const history = createBrowserHistory();

const App = () => {
    const [products, updateProducts] = useSessionStorage("cartProducts", []);

    const CartState = {
        products,
        updateProducts
    };
    
    return (
        <LocalizedRouter
            RouterComponent={BrowserRouter}
            languages={AppLanguage}
            appStrings={AppStrings}
            history={history}
        >
            <Seo />
            <ThemeProvider theme={theme}>
                <GlobalStyle />
                <CartContext.Provider value={CartState}>
                    <Container>
                        <Header />
                        <Navigation />
                        <AppSwitch />
                        <Footer />
                    </Container>
                </CartContext.Provider>
            </ThemeProvider>
        </LocalizedRouter>
    );
};

export default App;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const AppSwitch = () => (
    <Switch>
        <Route exact path="/:lang/search">
            <Search />
        </Route>
        <Route exact path="/:lang/cart">
            <Cart />
        </Route>
        <Route exact path="/:lang/cart/success/:order_id">
            <CartSuccess />
        </Route>
        <Route exact path="/:lang/category/:id/:name">
            <Category />
        </Route>
        <Route exact path="/:lang/view/:id/:title">
            <Product />
        </Route>
        <Route exact path="/:lang/contact-us">
            <Contacts />
        </Route>
        <Route exact path="/:lang/contact-us/success">
            <ContactSuccess />
        </Route>
        <Route exact path="/:lang/privacy">
            <Privacy />
        </Route>
        <Route exact path="/:lang/terms">
            <Terms />
        </Route>
        <Route exact path="/:lang/copyright">
            <Copyright />
        </Route>
        <Route exact path="/:lang/about-us">
            <About />
        </Route>
        <Route exact path="/:lang">
            <PageTitle>
                <FormattedMessage id="home.title" />
            </PageTitle>
            <ProductListing featured={true}></ProductListing>
        </Route>
    </Switch>
);
