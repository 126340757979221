import styled from 'styled-components';

const Overlay = styled.div`
    width: 100%;
    height: ${props => props.height || '100%'};
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    letter-spacing: 2px;
`;

export default Overlay;