import React, { useContext } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import {
    FiSearch,
    FiShoppingCart,
} from 'react-icons/fi';

import CartContext from '../helpers/CartContext';
import { NavLink } from './Link';
import LanguageSelector from './LanguageSelector';
import Logo from './Logo';
import FbLink from './FbLink';

const Header = () => {
    const intl = useIntl();
    let cartState = useContext(CartContext);

    return (
        <StyledHeader>
            <LanguageSelector />
            <Logo />
            <div>
                <NavLink to={`/${intl.locale}/search`} title={intl.formatMessage({id: 'search.title'})}>
                    <FiSearch />
                </NavLink>
                <NavLink to={`/${intl.locale}/cart`} title={`${cartState.products.length} ${intl.formatMessage({id: 'cart.items_in_cart'})}`}>
                    <FiShoppingCart />
                    {cartState.products.length > 0 ? <CartProductsLabel>{cartState.products.length}</CartProductsLabel> : null}
                </NavLink>
                <FbIconLink />
            </div>
        </StyledHeader>
    );
};

export default Header;

const StyledHeader = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px 5px 15px;

    ${NavLink} {
        padding: 0.7rem;
        font-size: 16px;
        position: relative;
    }
`;

const FbIconLink = styled(FbLink)`
    padding: 0.7rem;
    font-size: 16px;
`;

const CartProductsLabel = styled.div`
    background-color: ${props => props.theme.main};
    font-weight: 800;
    color: white;
    font-size: 13px;
    width: 16px;
    height: 16px;
    text-align: center;
    border-radius: 8px;
    position: absolute;
    bottom: 1px;
    right: -3px;
`;
