import styled, { css } from 'styled-components';

import { FiChevronLeft } from 'react-icons/fi';

const Chevron = styled(FiChevronLeft)`
    transition: all 0.15s ease-out;
    ${props => props.down && css`    
        transform: ${props => (props.down ? `rotate(-90deg)` : "")};
    `}
`;

export default Chevron;