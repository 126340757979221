import React from 'react';
import styled from 'styled-components';
import {
    FiPlus,
    FiMinus,
} from 'react-icons/fi';

import { Button } from './Button';

const QtyControl = ({ onChange, qty, ...props}) => {

    const updateQty = (val) => {
        const newval = parseInt(qty) + val;
        
        if(newval >= 0) {
            onChange(newval);
        }
    };

    return (
        <Container {...props}>
            <Button onClick={() => updateQty(-1)}><FiMinus /></Button>
            <span>{qty}</span>
            <Button onClick={() => updateQty(1)}><FiPlus /></Button>
        </Container>
    );
}

export default QtyControl;

const Container = styled.div`
    display: flex;
    border: 1px solid ${({ theme }) => theme.borderColor};

    ${Button} {
        padding: 5px;
        background-color: transparent;
        color: ${({ theme }) => theme.linkColor};
        
    }

    span {
        flex-grow: 1;
        font-size: 15px;
        padding: 3px 3px;
    }
`;