import React, { useState } from 'react';
import styled from 'styled-components';

import Chevron from './Chevron';
import { Button } from './Button';

const DropdownFilter = ({ title, children }) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <FilterButton link onClick={() => setOpen(!open)}>
                {title}
                <Chevron down={open ? 1 : 0} />
            </FilterButton>
            <FilterContent visible={open}>
                {children}
            </FilterContent>
        </>
    )
};

export default DropdownFilter;

const FilterButton = styled(Button)`
    padding: 1rem;
    width: 100%;
    justify-content: space-between;
    text-transform: uppercase;
    letter-spacing: 2px;
`;

const FilterContent = styled.div`
    height: auto;
    max-height: ${({ visible }) => visible ? '600px' : '0'};
    padding: 0 1rem;
    transition: max-height 200ms;
    overflow: hidden;
`;


