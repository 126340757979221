import React from 'react';
import styled from 'styled-components';
import { FiHelpCircle } from 'react-icons/fi';

const CartTotal = ({label, price, small, ...props}) => {
    return (
        <TotalWrap small={small} {...props}>
            {label}
            {price ? <span>{price}</span> : <FiHelpCircle data-tip={props.tooltip} />}
        </TotalWrap>
    );
};

export default CartTotal;

const TotalWrap = styled.span`
    padding: ${props => props.small ? '0.2rem' : '0.8rem'} 0;
    text-transform: uppercase;
    letter-spacing: 2px;
    display: flex;
    justify-content: space-between;
    font-size: ${props => props.small ? '14px' : '16px'};
    font-weight: ${props => props.small ? '400' : '600'};

    svg {
        font-size: 20px;
    }
`;
