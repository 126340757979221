import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { FiChevronLeft } from 'react-icons/fi';
import { useIntl, FormattedMessage } from 'react-intl';
import {
    FacebookShareButton,
    PinterestShareButton,
    TwitterShareButton,
    FacebookIcon,
    PinterestIcon,
    TwitterIcon
} from 'react-share';

import { AppLanguageId } from '../helpers/languages';
import CartContext from '../helpers/CartContext';
import api from '../helpers/request';
import Seo from '../helpers/Seo';
import Link from '../components/Link';
import Spinner from '../components/Spinner';
import Select from '../components/Select';
import Button from '../components/Button';
import Price from '../components/Price';
import PageContent from '../components/PageContent';
import Overlay from '../components/Overlay';
import TimeAgo from '../components/TimeAgo';

const Product = () => {
    const intl = useIntl();
    let history = useHistory();
    let { id, title } = useParams();

    let cartState = useContext(CartContext);

    let [product, setProduct] = useState(null);
    let [error, setError] = useState(null);
    let [imageLoading, setImageLoading] = useState(true);
    let [currentImage, setCurrentImage] = useState(0);
    let [selectedPriceOption, setSelectedPriceOption] = useState('framed');

    useEffect(() => {
        setError(null);

        api({ route: '/api/products/get', payload: {language_id: AppLanguageId[intl.locale], id: id} })
            .then(res => {
                if(res.status.code === 0) {
                    setCurrentImage(res.product.images[0]);
                    setProduct(res.product);

                    if(res.product.price && !res.product.price_with_frame) {
                        setSelectedPriceOption('unframed');
                    }
                } else {
                    setError(res.status.message);
                }
            })
            .catch((e) => {
                console.error(e);
            })
    }, [id, intl]);

    const onBuyNow = (aProduct) => {
        const framed = selectedPriceOption === 'framed';
        let found = false;

        cartState.products.forEach(product => {
            if(product.product_id === aProduct.product_id) {
                product.qty += 1;
                found = true;
            }
        });

        if(!found) {
            aProduct.qty = 1;
            aProduct.framed = framed;
            cartState.products.push(aProduct);
        }

        cartState.updateProducts([...cartState.products]);
        history.push(`/${intl.locale}/cart`);
    };

    const onChangeCurrentImage = (image) => {
        if(currentImage !== image) {
            setCurrentImage(image)
            setImageLoading(true);
        }
    }

    const onBuyPoster = () => {
        history.push(`/${intl.locale}/view/${product.poster_product.product_id}/${product.poster_product.title}`);
    }

    if(error) return (
        <>
            <Overlay>
                <Seo title={title} />
                {error}
            </Overlay>
            <Button link onClick={() => history.goBack()}>
                <FiChevronLeft /> <FormattedMessage id="common.back" />
            </Button>
        </>
    );

    if(!product) return (
        <Overlay>
            <Seo title={title} />
            <Spinner /><FormattedMessage id="common.loading" />
        </Overlay>
    );

    const discounted_price_unframed = product.price - (product.price * (product.discount / 100));
    const discounted_price_framed = product.price_with_frame - (product.price_with_frame * (product.discount / 100));

    const onFrameOptionChange = (e) => {
        setSelectedPriceOption(e.target.value)
    };

    let priceOptions = [];

    if(product.price_with_frame) {
        priceOptions.push({
            value: 'framed',
            label: intl.formatMessage({id:'product.with_frame'}) + ' ' + discounted_price_framed + 'лв.'
        });
    }

    if(product.price) {
        priceOptions.push({
            value: 'unframed',
            label: intl.formatMessage({id:'product.without_frame'}) + ' ' + discounted_price_unframed + 'лв.'
        });
    }

    const share_url = `https://api.art-review.net/share?lang_id=${AppLanguageId[intl.locale]}&product_id=${product.product_id}`;

    return (
        <PageContent width="1200px">
            <Seo 
                title={product.title} 
                description={product.description} 
                image={product.images[0].product_view_src} 
                keywords={product.keywords} />
            <Button link onClick={() => history.goBack()}>
                <FiChevronLeft /> <FormattedMessage id="common.back" />
            </Button>
            <Container>
                <Gallery>
                    <ProductImage src={currentImage.product_view_src} alt={product.title} loading={imageLoading ? 1 : 0} onLoad={() => setImageLoading(false)} />
                    <GalleryImages>
                        {product.images.map(image => (
                            <Button link onClick={() => onChangeCurrentImage(image)} key={image.thumb_src}>
                                <img src={image.thumb_src} alt={product.title} />
                            </Button>
                        ))}
                    </GalleryImages>
                </Gallery>
                <Information>
                    <h2>{product.title}</h2>

                    <InfoSection>
                        <h4>
                            <FormattedMessage id='product.price' />
                        </h4>
                        {product.price ? <Price product={product} info={' ('+intl.formatMessage({id:'product.without_frame'})+')'} /> : null}
                        {product.price_with_frame ? <Price product={product} frame info={' ('+intl.formatMessage({id:'product.with_frame'})+')'} /> : null}
                    </InfoSection>

                    <InfoSection>
                        <h4>
                            <FormattedMessage id='product.size' />
                        </h4>
                        {product.size ? <div>{product.size + ' ' + intl.formatMessage({id:'product.cm'}) + ' ('+intl.formatMessage({id:'product.without_frame'})+')'}</div> : null}
                        {product.size_with_frame ? <div>{product.size_with_frame + ' ' + intl.formatMessage({id:'product.cm'}) + ' ('+intl.formatMessage({id:'product.with_frame'})+')'}</div> : null} 
                    </InfoSection>

                    {product.subject ? <InfoSection>
                        <h4>
                            <FormattedMessage id="product.subject" />
                        </h4>
                        {product.subject}
                    </InfoSection> : null}

                    {/*
                    {product.author ? <InfoSection>
                        <h4>
                            <FormattedMessage id="product.author" />
                        </h4>
                        {product.author === 'Ivan Todorov' || product.author === 'Иван Тодоров' || product.author === 'Rosana Todorova' || product.author === 'Росана Тодорова' ? 
                            <Link to={`/${intl.locale}/about-us`}>{product.author}</Link>
                        : product.author}
                    </InfoSection> : null}
                    */}

                    {product.author ? <InfoSection>
                        <h4>
                            <FormattedMessage id="product.author" />
                        </h4>
                        {product.author}
                    </InfoSection> : null}

                    {product.materials.length > 0 ? <InfoSection>
                        <h4>
                            <FormattedMessage id="product.materials" />
                        </h4>
                        <ul>
                            {product.materials.map(material => (<li key={material}>{material}</li>))}
                        </ul>
                    </InfoSection> : null}

                    {product.description ? <InfoSection>
                        <h4>
                            <FormattedMessage id="product.description" />
                        </h4>
                        <Description>{product.description}</Description>
                    </InfoSection> : null}

                    <InfoSection>
                        <h4>
                            <FormattedMessage id="product.number" />
                        </h4>
                        #{product.product_id}
                    </InfoSection>

                    <InfoSection>
                        <h4>
                            <FormattedMessage id="product.ready_to_ship" />
                        </h4>
                        {product.available_qty > 0 ? 
                            intl.formatMessage({id: 'product.two_tree_days'}) : 
                            product.fabricatable ? 
                                `${product.fabrication_time_days + 3} ${intl.formatMessage({id: 'product.business_days'})}` : 
                                intl.formatMessage({id: 'product.unavailable'})
                        }
                    </InfoSection>

                    {product.views > 2 ? <p>{product.views} <FormattedMessage id="product.views" /> <TimeAgo date={new Date(product.last_viewed)} />.</p> : null}
                    
                    {priceOptions.length > 1 ?
                        <InfoSection>
                            <Select onChange={onFrameOptionChange}>
                                {priceOptions.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </Select>
                        </InfoSection>
                    : null}

                    <Button primary onClick={() => onBuyNow(product)}>
                        <FormattedMessage id="product.buy" /> &bull; {selectedPriceOption === 'framed' ? discounted_price_framed : discounted_price_unframed}лв.
                    </Button>
                    
                    {product.poster_product ? <Button primary onClick={() => onBuyPoster()}>
                        <FormattedMessage id="product.buy_poster" />
                    </Button> : null}
                    
                    <InfoSection center>
                        <h4>
                            <FormattedMessage id="product.information" />
                        </h4>
                        <Phone>+359 898 540 676</Phone>
                        <Phone>+359 888 715 906</Phone>
                    </InfoSection>

                    <Link to={`/${intl.locale}/contact-us?product_id=${product.product_id}`}>
                        <FormattedMessage id="product.diff_size" />
                    </Link>

                    <Link to={`/${intl.locale}/contact-us?product_id=${product.product_id}`}>
                        <FormattedMessage id="product.ask_question" />
                    </Link>

                    <ShareButtons>
                        <FacebookShareButton url={share_url}>
                            <FacebookIcon size={42} round={false} />
                        </FacebookShareButton>

                        <PinterestShareButton url={share_url} media={product.images[0].product_view_src} description={product.description}>
                            <PinterestIcon size={42} round={false} />
                        </PinterestShareButton>

                        <TwitterShareButton url={share_url}>
                            <TwitterIcon size={42} round={false} />
                        </TwitterShareButton>
                    </ShareButtons>
                </Information>
            </Container>
        </PageContent>
    )
};

export default Product;

const Container = styled.div`
    display: flex;
    margin-top: 15px;
    align-items: flex-start;

    @media (max-width: 420px) {
        flex-direction: column;
    }
`;

const Gallery = styled.div`
    margin-right: 4rem;
    flex-basis: 85%;

    @media (max-width: 420px) {
        flex-basis: 100%;
        margin: 1rem;
    }
`;

const ShareButtons = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
`;

const StyledProductImage = styled.div`
    background-color: white;
    text-align: center;
    padding: 1rem;
    color: ${({ theme }) => theme.color};
    font-size: 0.8rem;

    img {
        max-width: 100%;
        display: ${({visible}) => visible ? 'block' : 'none'};
        margin: auto;
    }
`;

const ProductImage = ({...props}) => {
    return (
        <StyledProductImage visible={!props.loading}>
            {props.loading ? <Overlay height="500px"><Spinner /><FormattedMessage id="common.loading" /></Overlay> : null}
            <img alt="test" {...props}/>
            &copy; {new Date().getFullYear()} Art Review <FormattedMessage id="common.all-rights-reserved" />
        </StyledProductImage>
    )
};

const GalleryImages = styled.div`
    display: flex;
    padding: 20px 0;
    flex-wrap: wrap;

    ${Button} {
        border: 1px solid ${({ theme }) => theme.borderColor};
        margin: 0.5rem 0.4rem 0 0;
        padding: 0.5rem;
        background: white;
    }
`;

const Information = styled.div`
    width: 35%;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    padding: 25px;

    h2 {
        text-transform: uppercase;
        letter-spacing: 2px;
        margin: 0 0 1.5rem 0;
    }

    ${Link} {
        margin-bottom: 10px;
        text-align: center;
        font-size: 16px;
        text-decoration: underline;
    }

    ${Button} {
        margin-bottom: 1.5rem;
    }

    p {
        margin: 0 0 1rem 0;
        color: ${({ theme }) => theme.main};
    }

    @media (max-width: 420px) {
        width: calc(100% - 50px);
        margin: 1rem;
    }
`;

const InfoSection = styled.div`
    margin-bottom: 1.5rem;
    text-align: ${props => props.center ? 'center' : 'inherit'};

    h4 {
        color: gray;
        font-size: 0.85rem;
        text-transform: uppercase;
        letter-spacing: 2px;
        padding-bottom: 2px; 
        margin: 0;
    }

    ul {
        padding: 0.5rem 1rem 0 1rem;
        margin: 0;
    }
`;

const Description = styled.pre`
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    white-space: pre-wrap;
    margin: 0;
`;

const Phone = styled.div`
    font-family: monospace;
`;