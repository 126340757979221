import React from 'react';
import styled from 'styled-components';
import { useIntl, FormattedMessage } from 'react-intl';

import Seo from '../helpers/Seo';
import PageTitle from '../components/PageTitle';
import PageContent from '../components/PageContent';

const About = () => {
    const intl = useIntl();
    
    return (
        <>
            <Seo title={intl.formatMessage({id: 'about.title'})} />
            <PageTitle>
                <FormattedMessage id="about.title" />
                <br/>
                <img src="/img/desk.png" alt="Art Review Desk" />
            </PageTitle>
            <PageContent width="800px">
                <AboutRow>
                    <img src="/img/ivan_todorov.jpg" alt={intl.formatMessage({id: 'about.ivan_name'})} />
                    <div>
                        <h2><FormattedMessage id="about.ivan_name" /></h2>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi elementum lorem ac eleifend mattis. Phasellus tempus a purus et facilisis. 
                            Pellentesque elementum nibh vel risus rhoncus, sed congue risus scelerisque. Donec eget velit mi. Mauris ut laoreet turpis. 
                        </p>
                        <p>
                            Cras placerat mauris imperdiet tortor mattis finibus. Sed eros ante, vulputate eu hendrerit at, consectetur id quam. 
                        </p>
                    </div>
                </AboutRow>
                <AboutRow>
                    <div>
                        <h2><FormattedMessage id="about.rosi_name" /></h2>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi elementum lorem ac eleifend mattis. Phasellus tempus a purus et facilisis. 
                            Pellentesque elementum nibh vel risus rhoncus, sed congue risus scelerisque. Donec eget velit mi. Mauris ut laoreet turpis. 
                        </p>
                        <p>
                            Cras placerat mauris imperdiet tortor mattis finibus. Sed eros ante, vulputate eu hendrerit at, consectetur id quam. 
                        </p>
                    </div>
                    <img src="/img/rossana_todorova.jpg" alt={intl.formatMessage({id: 'about.rosi_name'})} />
                </AboutRow>
            </PageContent>
        </>
    )
};

export default About;

const AboutRow = styled.div`
    display: flex;
    margin-bottom: 40px;

    div {
        padding: 0 20px;
    }

    img {
        min-width: 40%;
    }
`;