import styled from 'styled-components';

const PageTitle = styled.h2`
    font-family: Helvetica, "Helvetica Neue", Arial, "Lucida Grande", sans-serif;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 4rem 2rem;
    border-bottom: 1px solid ${({ theme }) => theme.borderColor};
    margin: 0;
    font-weight: 500;
`;

export default PageTitle;