import React, { useState } from 'react';
import styled from 'styled-components';
import ReactTooltip from "react-tooltip";
import { useHistory } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';

import Seo from '../helpers/Seo';
import api from '../helpers/request';
import useQuery from '../helpers/useQuery';
import PageTitle from '../components/PageTitle';
import { Button } from '../components/Button';
import Spinner from '../components/Spinner';
import PageContent from '../components/PageContent';
import { Input, Textarea, SectionTitle, SectionRow, Required } from '../components/Forms';

const Contacts = () => {
    const intl = useIntl();
    const query = useQuery();
    const history = useHistory();
    let [loading, setLoading] = useState(false);
    let [errors, setErrors] = useState({});

    const [contact, setContact] = useState({
        order_id: query.get('order_id') || "",
        product_id: query.get('product_id') || "",
        name: "",
        phone_or_email: "",
        content: ""
    });

    const onSend = () => {
        setLoading(true);

        api({ route: '/api/contacts/submit', payload: contact })
            .then(res => {
                setLoading(false);
                
                if(res.status.code !== 0) {
                    setErrors(res.errors || {});
                } else {
                    setContact({
                        order_id: null,
                        product_id: null,
                        name: "",
                        phone_or_email: "",
                        content: ""
                    });

                    history.push(`/${intl.locale}/contact-us/success`);
                }
            })
            .catch((e) => {
                setLoading(false);
                console.error(e);
            });
    };

    return (
        <>
            <Seo title={intl.formatMessage({id: 'contacts.title'})} />
            <PageTitle>
                <FormattedMessage id="contacts.title" />
            </PageTitle>
            <PageContent width="800px">
                <Info>
                    <div>
                        <h2>
                            <FormattedMessage id="contacts.legal_name" />
                        </h2>
                        <ul>
                            <li>
                                <strong><FormattedMessage id="common.address" />: </strong>
                                <FormattedMessage id="contacts.address" />
                            </li>
                            <li>
                                <strong><FormattedMessage id="common.phone" />: </strong>
                                +359 898 540 676 / +359 888 715 906
                            </li>
                            <li>
                                <strong><FormattedMessage id="common.email" />: </strong>
                                <a href="mailto:rossana_pl@abv.bg">rossana_pl@abv.bg</a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <iframe src="https://maps.google.com/maps?q=42.203285,24.6846193&z=15&output=embed" width="360" height="270" frameBorder="0" title="Map"></iframe>
                    </div>
                </Info>
                <div>
                    <SectionTitle data-tip={intl.formatMessage({id: 'common.required'})} data-place="left">
                        <FormattedMessage id="common.contact" />
                        <Required>*</Required>
                    </SectionTitle>
                    <SectionRow>
                        <Input
                            name="name"
                            type="text"
                            grow="2"
                            placeholder={intl.formatMessage({id: 'common.name'})}
                            autocomplete="name"
                            value={contact.name}
                            onChange={(e) => setContact({...contact, name: e.target.value})}
                            error={errors.name} />
                        <Input 
                            name="phone_or_email"
                            type="text"
                            grow="2"
                            placeholder={intl.formatMessage({id: 'contacts.phone_or_email'})}
                            value={contact.phone_or_email}
                            onChange={(e) => setContact({...contact, phone_or_email: e.target.value})}
                            error={errors.phone_or_email} />
                    </SectionRow>
                    <SectionTitle>
                        <FormattedMessage id="contacts.about" />
                    </SectionTitle>
                    <SectionRow>
                        <Input
                            name="product_id"
                            type="text"
                            grow="2"
                            placeholder={intl.formatMessage({id: 'contacts.product_number'})}
                            value={contact.product_id}
                            onChange={(e) => setContact({...contact, product_id: e.target.value})}
                            error={errors.product_id} />
                        <Input
                            name="order_id"
                            type="text"
                            grow="2"
                            placeholder={intl.formatMessage({id: 'contacts.order_number'})}
                            value={contact.order_id}
                            onChange={(e) => setContact({...contact, order_id: e.target.value})}
                            error={errors.order_id} />
                    </SectionRow>
                    <SectionTitle data-tip={intl.formatMessage({id: 'common.required'})} data-place="left">
                        <FormattedMessage id="contacts.message" />
                        <Required>*</Required>
                    </SectionTitle>
                    <SectionRow>
                        <Textarea 
                            grow="1"
                            placeholder={intl.formatMessage({id: 'cart.order_note_placeholder'})}
                            rows="8" 
                            value={contact.content} 
                            onChange={(e) => setContact({...contact, content: e.target.value})}
                            error={errors.content}></Textarea>
                    </SectionRow>
                    <Button fullWidth onClick={() => onSend()}>
                        <FormattedMessage id="contacts.send" />
                        {loading ? (<Spinner nopad />) : null}
                    </Button>
                </div>
            </PageContent>
            <ReactTooltip effect="solid" html />
        </>
    )
};

export default Contacts;

const Info = styled.div`
    display: flex;
    margin-bottom: 20px;

    h2 {
        margin-top: 0;
    }

    ul {
        padding: 0 1rem;
        margin: 0;

        li {
            margin-top: 5px;
        }
    }

    iframe {
        border: 0;
        width: 360px;
        height: 270px;
    }
`;
