import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import Seo from '../helpers/Seo';
import useDebounce from '../helpers/debounce';
import PageTitle from '../components/PageTitle';
import ProductListing from '../components/ProductListing';
import { Input as BaseInput } from '../components/Forms';

const Search = () => {
    const intl = useIntl();
    const inputEl = useRef(null);
    const [term, setTerm] = useState("");
    const debouncedSearchTerm = useDebounce(term, 500);
    
    useEffect(() => {
        inputEl.current.focus();
    });

    return (
        <>
            <Seo title={intl.formatMessage({ id: 'search.title' })} />
            <PageTitle>
                <Input 
                    ref={inputEl} 
                    value={term} 
                    onChange={(e) => setTerm(e.target.value)} 
                    placeholder={intl.formatMessage({id: 'search.input-placeholder'})} />
            </PageTitle>
            <ProductListing term={debouncedSearchTerm}></ProductListing>
        </>
    )
};

export default Search;

const Input = styled(BaseInput).attrs({ type: 'search' })`
    letter-spacing: 2px;
    width: 25%;
    font-size: 80%;
`;