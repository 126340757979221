import React from 'react';
import styled from 'styled-components';

const Striked = styled.span`
    font-size: 0.9rem;
    color: #999;
    text-decoration: line-through;
`;

const Info = styled.span`
    color: gray;
    font-weight: normal;
    font-size: 0.9rem;
`;

const PriceInner = (props) => {
    const qty = props.product.qty || 1;
    const price = props.frame ? props.product.price_with_frame : props.product.price;

    if(props.product.discount > 0) {
        const discounted_price = (price - (price * (props.product.discount / 100))) * qty;

        return (
            <h3 className={props.className}>
                {props.reverse ? (<Striked>{price * qty}лв.</Striked>) : (<></>)}
                {discounted_price}лв.
                {!props.reverse ? (<Striked>{price * qty}лв.</Striked>) : (<></>)}
                {props.info ? <Info>{props.info}</Info> : null}
            </h3>
        );
    } else {
        return (
            <h3 className={props.className}>
                {price * qty}лв.
                {props.info ? <Info>{props.info}</Info> : null}
            </h3> 
        );
    }
};

const Price = styled(PriceInner)`
    margin: 0;
    font-weight: 600;
`;

export default Price;