
export const api = async ({
    route, payload, headers, ...rest
}) => {
    const settings = {
        ...rest,
        method: 'POST',
        headers: {
            ...headers,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    };

    const response = await fetch(`https://api.art-review.net${route}`, settings);
    //const response = await fetch(`http://localhost${route}`, settings);
    const result = await response.json();

    return result;
};

export default api;