import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { FiGlobe } from 'react-icons/fi';

import AppLanguage from '../helpers/languages';
import { NavLink } from './Link';
import Chevron from './Chevron';
import Button from './Button';

const LanguageSelector = () => {
    const node = useRef();
    const [open, setOpen] = useState(false);
    const { pathname } = useLocation();
    const { locale } = useIntl();

    const handleClick = e => {
        if (node.current.contains(e.target)) {
            return;
        }
        
        setOpen(false);
    };

    const getMatchingRoute = language => {
        const [, route] = pathname.split(locale);
        return `/${language}` + route;
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);

        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    return (
        <DropdownWrapper ref={node}>
            <Button link onClick={() => setOpen(!open)}>
                <GlobeIcon />
                <span>Language</span>
                <Chevron down={open ? 1 : 0} />
            </Button>
            <Dropdown visible={open}>
                <NavLink to={getMatchingRoute(AppLanguage.English)} onClick={() => setOpen(false)}>
                    <img src="/img/english-flag.png" alt="English flag" />
                    English
                </NavLink>
                <NavLink to={getMatchingRoute(AppLanguage.Bulgarian)} onClick={() => setOpen(false)}>
                    <img src="/img/bulgarian-flag.png" alt="Bulgarian flag" />
                    Български
                </NavLink>
            </Dropdown>
        </DropdownWrapper>
    );
}

export default LanguageSelector;

const DropdownWrapper = styled.div`
    position: relative;

    ${Button} {
        padding: 0.7rem;
        font-size: 14px;
        text-transform: none;

        span {
            @media (max-width: 420px) {
                display: none;
            }
        }
    }
`;

const GlobeIcon = styled(FiGlobe)`
    margin-right: 3px;
`;

const Dropdown = styled.div`
    position: absolute;
    display: ${({ visible }) => visible ? 'block' : 'none'};
    border: 1px solid ${({ theme }) => theme.borderColor};
    padding: 0.5rem 0;
    background-color: white;
    z-index: 1000;

    ${NavLink} {
        display: block;
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem;

        img {
            width: 20px;
            margin-right: 10px;
        }
    }
`;
