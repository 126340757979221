import React from 'react';
import styled from 'styled-components';

const Logo = () => {
    return (
        <Container>
            <img src="/img/LogoHatW50Transperant.png" alt="Art Review" />
            Art Review
        </Container>
    )
};

export default Logo;

const Container = styled.h1`
    font-family: 'Arapey', Georgia, "Times New Roman", Times, serif;
    font-size: 33px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;

    img {
        margin-right: 8px;
        width: 43px;

        @media (max-width: 420px) {
            width: 20px;
        }
    }

    @media (max-width: 420px) {
        font-size: 20px;
    }
`;