import React, { useState, useEffect, useRef } from 'react'
import Masonry from 'react-masonry-component';
import styled from 'styled-components';
import { useIntl, FormattedMessage } from 'react-intl';

import { useScrollPosition } from '../helpers/useScrollPosition';
import api from '../helpers/request';
import { AppLanguageId } from '../helpers/languages';
import DropdownFilter from './DropdownFilter';
import ProductListItem from './ProductListItem';
import { Label, Checkbox } from './Forms';
import Spinner from './Spinner';
import PageContent from './PageContent';
import Overlay from './Overlay';

const Filters = styled.div`
    min-width: 18%;
    padding: 0 1.5rem;
`;

const ListingCnt = styled.div`
    flex-grow: 1;
    margin-right: 2rem;
    position: relative;
`;

const Listing = styled(Masonry)`
    transition: opacity 0.15s;
`;

const masonryOptions = {
    transitionDuration: 0,
    percentPosition: true,
    horizontalOrder: true,
    columnWidth: '.grid-sizer',
    itemSelector: '.grid-item',
};

const ProductListing = ({
    category_id,
    term,
    featured
}) => {
    const listingEl = useRef();
    const intl = useIntl();
    const [isLoaded, setIsLoaded] = useState(false);
    const [materials, setMaterials] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [products, setProducts] = useState([]);
    const [visibleProductCount, setVisibleProductCount] = useState(10);
    const [sort_by, setSortBy] = useState('price_asc');
    const [filters, setFilters] = useState({
        subject_id: null,
        materials: new Map(),
        price_class: null,
        size_class: null,
        orientation: null
    });

    useEffect(() => {
        api({ route: '/api/materials/list', payload: {language_id: AppLanguageId[intl.locale]} })
            .then(res => setMaterials(res.materials))
            .catch(() => {})
    }, [intl]);

    useEffect(() => {
        api({ route: '/api/subjects/list', payload: {language_id: AppLanguageId[intl.locale]} })
            .then(res => setSubjects(res.subjects))
            .catch(() => {})
    }, [intl]);

    useEffect(() => {
        let materials = [];
        filters.materials.forEach((v, k, m) => materials.push(k)); // IE 11 supports only foreach

        const payload = {
            ...filters,
            language_id: AppLanguageId[intl.locale],
            category_id: category_id,
            featured: featured,
            term: term,
            materials: materials
        };

        if(filters.price_class === 'tier1') {
            payload.price_from = null;
            payload.price_to = 20;
        } else if(filters.price_class === 'tier2') {
            payload.price_from = 21;
            payload.price_to = 60;
        } else if(filters.price_class === 'tier3') {
            payload.price_from = 61;
            payload.price_to = 100;
        } else if(filters.price_class === 'tier4') {
            payload.price_from = 101;
            payload.price_to = null;
        }

        setIsLoaded(false);

        api({ route: '/api/products/search', payload: payload })
            .then(res => {
                setIsLoaded(true);
                setProducts(res.products);
            })
            .catch((e) => {
                setIsLoaded(true);
                console.error(e);
            });

    }, [intl, category_id, term, featured, filters]);

    const handleMaterialChange = (e, id) => {
        if (e.target.checked) {
            filters.materials.set(id, id)
        } else {
            filters.materials.delete(id);
        }

        setFilters(filters => ({...filters}));
    };

    const handleSubjectChange = (id) => {
        setFilters(filters => ({
            ...filters, 
            subject_id: filters.subject_id === id ? null : id
        }));
    };

    const handleSizeClassChange = (size_class) => {
        setFilters(filters => ({
            ...filters, 
            size_class: filters.size_class === size_class ? null : size_class
        }));
    };

    const handleOrientationChange = (orientation) => {
        setFilters(filters => ({
            ...filters, 
            orientation: filters.orientation === orientation ? null : orientation
        }));
    };

    const handlePriceChange = (price_class) => {
        setFilters(filters => ({
            ...filters, 
            price_class: filters.price_class === price_class ? null : price_class
        }));
    };

    useScrollPosition(({ prevPos, currPos }) => {
        if(listingEl.current.getBoundingClientRect().bottom <= window.innerHeight) {
            setVisibleProductCount(visibleProductCount + 2);
        }
    }, [listingEl, visibleProductCount]);

    products.sort((a, b) => {
        if(sort_by === 'price_asc') {
            return a.price > b.price;
        } else if(sort_by === 'price_desc') {
            return a.price < b.price;
        } else {
            return a.product_id < b.product_id;
        }
    });

    return (
        <PageContent flex>
            <Filters>

                <DropdownFilter title={intl.formatMessage({id: 'filters.sort'})}>
                    <Label>
                        <Checkbox checked={sort_by === 'new'} onChange={(e) => setSortBy('new')} />
                        <FormattedMessage id="filters.recently_added" />
                    </Label>
                    <Label>
                        <Checkbox checked={sort_by === 'price_asc'} onChange={(e) => setSortBy('price_asc')} />
                        <FormattedMessage id="filters.price_low_to_high" />
                    </Label>
                    <Label>
                        <Checkbox checked={sort_by === 'price_desc'} onChange={(e) => setSortBy('price_desc')} />
                        <FormattedMessage id="filters.price_high_to_low" />
                    </Label>
                </DropdownFilter>

                <DropdownFilter title={intl.formatMessage({id: 'filters.price'})}>
                    <Label>
                        <Checkbox checked={filters.price_class === 'tier1'} onChange={(e) => handlePriceChange('tier1')}/>
                        <FormattedMessage id="filters.under_20" />
                    </Label>
                    <Label>
                        <Checkbox checked={filters.price_class === 'tier2'} onChange={(e) => handlePriceChange('tier2')}/>
                        <FormattedMessage id="filters.20_to_60" />
                    </Label>
                    <Label>
                        <Checkbox checked={filters.price_class === 'tier3'} onChange={(e) => handlePriceChange('tier3')}/>
                        <FormattedMessage id="filters.60_to_100" />
                    </Label>
                    <Label>
                        <Checkbox checked={filters.price_class === 'tier4'} onChange={(e) => handlePriceChange('tier4')}/>
                        <FormattedMessage id="filters.over_100" />
                    </Label>
                </DropdownFilter>

                <DropdownFilter title={intl.formatMessage({id: 'filters.size'})}>
                    <Label>
                        <Checkbox checked={filters.size_class === 'small'} onChange={(e) => handleSizeClassChange('small')}/>
                        <FormattedMessage id="filters.small" />
                    </Label>
                    <Label>
                        <Checkbox checked={filters.size_class === 'medium'} onChange={(e) => handleSizeClassChange('medium')}/>
                        <FormattedMessage id="filters.medium" />
                    </Label>
                    <Label>
                        <Checkbox checked={filters.size_class === 'large'} onChange={(e) => handleSizeClassChange('large')}/>
                        <FormattedMessage id="filters.large" />
                    </Label>
                </DropdownFilter>

                <DropdownFilter title={intl.formatMessage({id: 'filters.subject'})}>
                    {subjects.map(subject => (
                        <Label key={subject.subject_id}>
                            <Checkbox checked={filters.subject_id === subject.subject_id} onChange={(e) => handleSubjectChange(subject.subject_id)}/>
                            {subject.name}
                        </Label>
                    ))}
                </DropdownFilter>
                
                <DropdownFilter title={intl.formatMessage({id: 'filters.material'})}>
                    {materials.map(material => (
                        <Label key={material.material_id}>
                            <Checkbox checked={filters.materials.has(material.material_id)} onChange={(e) => handleMaterialChange(e, material.material_id)}/>
                            {material.name}
                        </Label>
                    ))}
                </DropdownFilter>

                <DropdownFilter title={intl.formatMessage({id: 'filters.orientation'})}>
                    <Label>
                        <Checkbox checked={filters.orientation === 'landscape'} onChange={(e) => handleOrientationChange('landscape')}/>
                        <FormattedMessage id="filters.horizontal" />
                    </Label>
                    <Label>
                        <Checkbox checked={filters.orientation === 'portrait'} onChange={(e) => handleOrientationChange('portrait')}/>
                        <FormattedMessage id="filters.vertical" />
                    </Label>
                    <Label>
                        <Checkbox checked={filters.orientation === 'square'} onChange={(e) => handleOrientationChange('square')}/>
                        <FormattedMessage id="filters.square" />
                    </Label>
                </DropdownFilter>

            </Filters>
            <ListingCnt ref={listingEl}>
                <Listing
                    className={isLoaded ? '' : 'loading'} // default ''
                    elementType={'div'} // default 'div'
                    options={masonryOptions} // default {}
                    disableImagesLoaded={false} // default false
                    updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
                >
                    <div className="grid-sizer"></div>
                    {products.slice(0, visibleProductCount).map(p => (
                        <ProductListItem product={p} key={p.product_id} />
                    ))}
                </Listing>
                {
                    !isLoaded
                    ? <Overlay><Spinner /><FormattedMessage id="common.loading" /></Overlay> 
                    : products.length === 0
                        ?  <Overlay><FormattedMessage id="filters.no_products" /></Overlay> 
                        : null
                }
            </ListingCnt>
            
            
        </PageContent>
    );
}

export default ProductListing;