import styled, { css } from 'styled-components';

const PageContent = styled.main`
    margin-top: 40px;

    ${props => props.width && css`
        width: ${props.width};
        align-self: center;

        @media (max-width: ${props.width}) {
            width: 100%;
        }
    `}
    
    ${props => props.paper && css`
        padding: 20px 40px;
        background-color: white;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    `}

    ${props => props.center && css`
        text-align: center;
    `}

    ${props => props.flex && css`
        display: flex;
        flex: 1;

        @media (max-width: 420px) {
            flex-direction: column;
        }
    `}
`;

export default PageContent;