import React from 'react';
import styled from 'styled-components';
import { useIntl, FormattedMessage } from 'react-intl';

import { Link } from './Link';
import FbLink from './FbLink';

const Footer = () => {
    const { locale } = useIntl();

    return (
        <StyledFooter>
            <FooterRow>
                <FooterLogo to={`/${locale}/`}>
                    &copy; 2005 - {new Date().getFullYear()} Art Review
                </FooterLogo>
                <div>
                    <FooterLink to={`/${locale}/contact-us`}>
                        <FormattedMessage id="contacts.title" />
                    </FooterLink>
                    {/*
                    <FooterLink to={`/${locale}/about-us`}>
                        <FormattedMessage id="about.title" />
                    </FooterLink>
                    */}
                    <FooterLink to={`/${locale}/terms`}>
                        <FormattedMessage id="terms.title" />
                    </FooterLink>
                    <FooterLink to={`/${locale}/privacy`}>
                        <FormattedMessage id="privacy.title" />
                    </FooterLink>
                    <FooterLink to={`/${locale}/copyright`}>
                        <FormattedMessage id="copyright.title" />
                    </FooterLink>

                    <FooterFbLink />
                </div>
            </FooterRow>
            <FooterRow>
                <Legal><FormattedMessage id="common.all-rights-reserved" /></Legal>
            </FooterRow>
        </StyledFooter>
    )
}

export default Footer;

const StyledFooter = styled.footer`
    padding: 25px;
    border-top: 1px solid ${({ theme }) => theme.borderColor};
    margin-top: 4rem;
`;

const FooterRow = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 5px;

    @media (max-width: 420px) {
        flex-direction: column;

        div {
            display: flex;
            flex-direction: column;
        }
    }
`;

const FooterLogo = styled(Link)`
    font-family: 'Arapey', Georgia, "Times New Roman", Times, serif;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1.5px;
`;

const FooterLink = styled(Link)`
    margin-left: 2rem;

    @media (max-width: 420px) {
        margin: 0;
    }
`;

const FooterFbLink = styled(FbLink)`
    margin-left: 1rem;

    @media (max-width: 420px) {
        margin: 0;
    }
`;

const Legal = styled.span`
    font-size: 12px;
`;
