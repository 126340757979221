import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Seo from '../helpers/Seo';
import PageTitle from '../components/PageTitle';
import PageContent from '../components/PageContent';

const Copyright = () => {
    const intl = useIntl();

    let content = null;

    if(intl.locale === 'en') {
        content = (
            <>
                <p>
                    &copy; {new Date().getFullYear()} Art Review All Rights Reserved
                </p>

                <p>
                    All files and information contained in this Website are copyright by Sole Trader REVIEW R.Todorova (DBA Art Review), and may not be duplicated, copied, modified or adapted, in any way without our written permission. 
                </p>
                <p>
                    Our Website may contain our service marks or trademarks as well as those of our affiliates or other companies, in the form of words, graphics, and logos.
                </p>
                <p>
                    Your use of our Website or Services does not constitute any right or license for you to use our service marks or trademarks, without the prior written permission of Sole Trader Review R.Todorova.
                </p>
                <p>
                    Our Content, as found within our Website and Services, is protected under Bulgarian, EU and foreign copyrights. The copying, redistribution, use or publication by you of any such Content, is strictly prohibited. Your use of our Website and Services does not grant you any ownership rights to our Content.
                </p>
            </>
        );
    } else {
        content = (
            <>
                <p>
                    &copy; {new Date().getFullYear()} Арт Ревю всички права запазени
                </p>

                <p>
                    Всички файлове и информация които се намират на https://art-review.net са собственост на ЕТ Арт Ревю Р.Тодорова и не могат да бъдат копирани, променяни или адаптирани, в какъвто и да е формат, без писменото съгласие на ЕТ Арт Ревю Р.Тодорова.
                </p>

                <p>
                    По-специално, всички репродукции, адаптации, съхранение и преработване в други медии, включително съхранение или прехвърляне в друга медия посредством електронни средства, се ползват от защитата на авторското право и са забранени за търговски и каквито и да е други цели без писменото съгласие на ЕТ Арт Ревю Р.Тодорова.
                </p>
            </>
        );
    }

    return (
        <>
            <Seo title={intl.formatMessage({id: 'copyright.title'})} />
            <PageTitle><FormattedMessage id="copyright.title" /></PageTitle>
            <PageContent width="800px">
                {content}
            </PageContent>
        </>
    )
};

export default Copyright;