
import styled, { css } from 'styled-components';

export const Button = styled.button.attrs({ type: 'button' })`
    border: none;
    color: ${({ link, theme, primary }) => 
        link
            ? primary
                ? theme.main
                : theme.linkColor
            : 'white'
    };
    padding: ${({ link }) => link ? '0' : '0.8rem 1rem'};
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    opacity: 0.8;
    text-transform: uppercase;
    letter-spacing: 1px;
    background-color: ${({ disabled, primary, link, theme, }) => 
        link
            ? 'transparent'
            : disabled
                ? theme.disabledColor
                : primary
                    ? theme.main
                    : theme.linkColor
    };

    &:hover {
        cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
        opacity: ${props => props.disabled ? 0.8 : 1.0};
    }

    ${props => props.fullWidth && css`
        width: 100%;
    `}
`;

export default Button;
