//import React from 'react';
import styled from 'styled-components';
import { NavLink as RouterNavLink, Link as RouterLink } from 'react-router-dom';

export const ExtLink = styled.a`
    color: ${props => props.theme.linkColor};
    font-size: 14px;
    text-decoration: none;

    :hover {
        color: ${props => props.theme.linkHoverColor};
    }
`;

export const Link = styled(RouterLink)`
    color: ${props => props.primary ? props.theme.main : props.theme.linkColor};
    font-size: 14px;
    text-decoration: none;

    :hover {
        color: ${props => props.theme.linkHoverColor};
    }
`;

export const NavLink = styled(RouterNavLink)`
    color: ${props => props.theme.linkColor};
    font-size: 14px;
    text-decoration: none;

    :hover {
        color: ${props => props.theme.linkHoverColor};
    }

    &.active {
        color: ${props => props.theme.main }
    }
`

export default Link;