const theme = {
    main: "#DC5634",
    color: "rgb(69, 69, 69)",
    success: "#77dd77",
    linkColor: "#666666",
    linkHoverColor: "#444444",
    disabledColor: "#CCC",
    borderColor: "#dee2e6",
    bodyBackground: "hsl(0, 0%, 97%)",
};

export default theme;