import React from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';

import Seo from '../helpers/Seo';
import PageTitle from '../components/PageTitle';
import { Button } from '../components/Button';
import PageContent from '../components/PageContent';
import SuccessIcon from '../components/SuccessIcon';

const ContactSuccess = () => {
    const history = useHistory();
    const intl = useIntl();

    return (
        <>
            <Seo title={intl.formatMessage({id: 'contacts.title'})} />
            <PageTitle><FormattedMessage id="contacts.title" /></PageTitle>
            <PageContent paper center width="600px">
                <h2>
                    <FormattedMessage id="contact-success.title" />
                </h2>
                <SuccessIcon />
                <p>
                    <FormattedMessage id="contact-success.message" />
                </p>
                <Button primary onClick={() => history.push(`/${intl.locale}/`)}>
                    <FormattedMessage id="common.back-to-store" />
                </Button>
            </PageContent>
        </>
    )
};

export default ContactSuccess;