import React from 'react';
import ExtTimeAgo from 'react-timeago'
import bgStrings from 'react-timeago/lib/language-strings/bg'
import enStrings from 'react-timeago/lib/language-strings/en'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import { useIntl } from 'react-intl';

const bgFormatter = buildFormatter(bgStrings);
const enFormatter = buildFormatter(enStrings);

const TimeAgo = (props) => {
    const { locale } = useIntl();

    let formatter = enFormatter;

    if(locale === 'bg') {
        formatter = bgFormatter;
    }

    return (
        <ExtTimeAgo formatter={formatter} {...props} />
    )
};

export default TimeAgo;