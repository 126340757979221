import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import Seo from '../helpers/Seo';
import PageTitle from '../components/PageTitle';
import { Link } from '../components/Link';
import { Button } from '../components/Button';
import PageContent from '../components/PageContent';
import SuccessIcon from '../components/SuccessIcon';

const CartSuccess = () => {
    const history = useHistory();
    const intl = useIntl();
    const { order_id } = useParams();

    return (
        <>
            <Seo title={intl.formatMessage({id: 'cart-success.title'})} />
            <PageTitle><FormattedMessage id="cart.title" /></PageTitle>
            <PageContent paper center width="600px">
                <h2><FormattedMessage id="cart-success.subtitle" /></h2>
                <SuccessIcon />
                <OrderIdCnt>
                    <FormattedMessage id="cart-success.order_number" />: {order_id}
                </OrderIdCnt>
                <HelpText>
                    <FormattedMessage id="cart-success.copy1" />
                    <Link to={`/${intl.locale}/contact-us`} primary="true">
                        <FormattedMessage id="cart.footer.contact_us" />
                    </Link>
                    <FormattedMessage id="cart-success.copy2" />
                </HelpText>
                <Button primary onClick={() => history.push(`/${intl.locale}/`)}>
                    <FormattedMessage id="common.back-to-store" />
                </Button>
            </PageContent>
        </>
    )
};

export default CartSuccess;

const OrderIdCnt = styled.div`
    font-size: 22px;
    margin: 20px 0;
    padding: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    border: 1px solid ${({ theme }) => theme.success};
`;

const HelpText = styled.p`
    ${Link} {
        font-size: 16px;
    }
`;