import React from 'react';
import styled, { css } from 'styled-components';

import { FiCheck } from 'react-icons/fi';

export const Label = styled.label`
    display: block;
    margin: 0.75rem 0;
`;

export const CheckboxLabel = styled(Label)`
    display: flex;
    align-items: center;
`;

const CheckIcon = styled(FiCheck)``;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
    clippath: inset(50%);
`;

const StyledCheckbox = styled.div`
    display: inline-flex;
    width: 20px;
    height: 20px;
    transition: all 150ms;
    border: 1px solid ${props => props.checked ? props.theme.main : '#555'};
    color: ${props => props.checked ? props.theme.main : 'transperant'};
    align-items: center;
    justify-content: center;

    ${HiddenCheckbox}:focus + & {
        box-shadow: 0 0 0 3px pink;
    }

    ${CheckIcon} {
        visibility: ${props => props.checked ? 'visible' : 'hidden'}
    }
`;

const CheckboxContainer = styled.div`
    display: inline-block;
    vertical-align: middle;
    margin-right: 1.5rem;
`;

export const Checkbox = ({ className, checked, onChange, ...props }) => (
    <CheckboxContainer className={className}>
        <HiddenCheckbox checked={checked} onChange={onChange} {...props}/>
        <StyledCheckbox checked={checked}>
            <CheckIcon />
        </StyledCheckbox>
    </CheckboxContainer>
);

export const Input = styled.input.attrs({ type: 'text', size: '1' })`
    padding: 0.6rem 1rem;
    box-shadow: none;
    appearance: none;
    border: 0.1rem solid ${props => props.error ? props.theme.main : '#d1d1d1'};
    
    &::placeholder {
        color: #535353;
    }

    ${props => props.grow && css`
        flex-grow: ${props.grow};
    `}
`;

export const Textarea = styled.textarea`
    padding: 0.6rem 1rem;
    border: 0.1rem solid ${props => props.error ? props.theme.main : '#d1d1d1'};
    
    &::placeholder {
        color: #535353;
    }

    ${props => props.grow && css`
        flex-grow: ${props.grow};
    `}
`;

export const SectionTitle = styled.h4`
    color: gray;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 2px;
    display: flex;
    justify-content: space-between;

    div {
        margin-right: 0.2rem;
    }
`;

export const SectionRow = styled.div`
    display: flex;
    margin-bottom: 1rem;

    ${Input}:not(:last-child) {
        margin-right: 10px;
    }
`;

const RequiredCnt = styled.span`
    color: red;
    flex-grow: 1;
`;

export const Required = () => (
    <RequiredCnt>*</RequiredCnt>
);