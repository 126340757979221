import React from 'react';
import styled from 'styled-components';
import { useIntl, FormattedMessage } from 'react-intl';

import Price from '../components/Price';
import QtyControl from '../components/QtyControl';
import Button from '../components/Button';
import { Link } from '../components/Link';

const CartProductItem = ({ product, onQtyChange }) => {
    const { locale } = useIntl();

    return (
        <Container>
            <img src={product.images[0].thumb_src} alt={product.title} title={product.title} />
            
            <Link to={`/${locale}/view/${product.product_id}/${product.title}`}>
                {product.title}
                <br/>
                <FormattedMessage id={product.framed ? 'cart.framed' : 'cart.no_frame'} />
            </Link>

            <QtySection>
                <QtyControl qty={product.qty} onChange={(val) => onQtyChange(val)} />
                <Button link primary onClick={() => onQtyChange(0)}>
                    <FormattedMessage id="cart.remove" />
                </Button>
            </QtySection>

            <Price product={product} frame={product.framed} reverse />
        </Container>
    )
};

export default CartProductItem;

const Container = styled.div`
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.borderColor};
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${Link} {
        flex-grow: 1;
    }

    ${Price} {
        text-align: right;
        margin-left: 20px;
        max-width: 100px;
        width: 100px;
        font-weight: 500;
        font-size: 16px;
    }

    img {
        margin-right: 10px;
        flex-basis: 100px;
        width: 100px;
        min-width: 100px;
    }
`;

const QtySection = styled.div`
    text-align: center;

    & > ${Button} {
        font-size: 12px;
        margin-top: 5px;
    }
`;