import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import AppLanguage from './languages';

const Seo = ({
    title,
    description,
    keywords,
    image
}) => {
    const { pathname } = useLocation();
    const intl = useIntl();
    const url = window.location.href;
    const image_src = image ? image : url+'img/desk.png';

    let og_locale = 'bg_BG';
    let og_locale_alt = 'en_US';

    if(intl.locale === 'en') {
        og_locale = 'en_US';
        og_locale_alt = 'bg_BG';
    }

    if(keywords) {
        keywords += ',';
    } else {
        keywords = '';
    }

    keywords += intl.formatMessage({id: 'keywords'});

    if(!description) {
        description = intl.formatMessage({id: 'description'});
    }

    return (
        <Helmet
            defaultTitle={intl.formatMessage({id: 'site_title'})}
            titleTemplate={`%s | ${intl.formatMessage({id: 'site_title'})}`}>

            <html lang={intl.locale} />

            {/* General tags */}
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            
            {/* OpenGraph tags */}
            <meta property="og:url" content={url} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image_src} />
            <meta property="og:type" content="website" />
            <meta property="fb:app_id" content="3441766429186504" />
            <meta property="og:locale" content={og_locale} />
            <meta property="og:locale:alternate" content={og_locale_alt} />
            <meta property="og:site_name" content={intl.formatMessage({id: 'site_name'})} />

            {/* Twitter Card tags */}
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image_src} />
            <meta name="twitter:card" content="summary_large_image" />
            {/*<meta name="twitter:creator" content={config.authorTwitterAccount ? config.authorTwitterAccount : ""} />*/}

            {/* lang tags */}
            <link rel="alternate" href={getMatchingRoute(AppLanguage.English)} hreflang="x-default" />
            <link rel="alternate" href={getMatchingRoute(AppLanguage.English)} hreflang="en" />
            <link rel="alternate" href={getMatchingRoute(AppLanguage.Bulgarian)} hreflang="bg" />
        </Helmet>
    );

    function getMatchingRoute(language) {
        const [, route] = pathname.split(intl.locale);
        return window.location.origin + `/${language}` + route;
    }
};

export default Seo;