import React from 'react';
import styled from 'styled-components';
import { FiFacebook } from 'react-icons/fi';

import { ExtLink } from './Link';

const FbIcon = styled(FiFacebook)`
    color: #3b5998;
`;

const FbLink = (props) => (
    <ExtLink href="https://www.facebook.com/artreviewbg/" target="_blank" rel="noopener noreferrer" {...props}>
        <FbIcon />
    </ExtLink>
);

export default FbLink;