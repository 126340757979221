import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { FiPhone } from 'react-icons/fi';

import { NavLink } from './Link';
import { AppLanguageId } from '../helpers/languages';
import api from '../helpers/request';

const Navigation = () => {
    const { locale } = useIntl();
    const [categories, setCategories] = useState([]);
 
    useEffect(() => {
        api({ route: '/api/categories/list', payload: {language_id: AppLanguageId[locale]} })
            .then(res => setCategories(res.categories))
            .catch(() => {})
    }, [locale]);

    return (
        <NavigationContainer>
            <Phone>
                <FiPhone /> +359 888 715 906
            </Phone>

            <NavLink exact to={`/${locale}/`}>
                <FormattedMessage id="home.title" />
            </NavLink>
            
            {categories.map(category => (
                <NavLink to={`/${locale}/category/`+category.category_id+'/'+category.name} key={category.category_id}>
                    {category.name}
                </NavLink>
            ))}
        </NavigationContainer>
    );
}

export default Navigation;

const NavigationContainer = styled.nav`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    border-bottom: 1px solid ${({ theme }) => theme.borderColor};

    @media (max-width: 420px) {
        flex-direction: column;
    }

    a {
        text-transform: uppercase;
        margin: 0 0.75rem;
        letter-spacing: 2px;
        padding: 1rem 0;
        position: relative;
        font-size: 15px;

        :after {
            content: '';
            display: block;
            position: absolute;
            left: 50%;
            margin: 15px auto;
            height: 2px;
            width: 0px;
            background: transparent;
            transition: left .5s ease, width .5s ease, background-color .5s ease;
        }

        :hover:after {
            width: 100%;
            left: 0%;
            background: ${({ theme }) => theme.main};
        }

        &.active:after {
            width: 100%;
            left: 0%;
            background: ${({ theme }) => theme.main};
        }
    }
`;

const Phone = styled.span`
    position: absolute;
    left: 0;
    bottom: 0.75rem;
    margin-left: 1.6rem;
    font-size: 0.9rem;
    font-family: monospace;

    @media (max-width: 420px) {
        display: none;
    }
`;