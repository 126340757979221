import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import Link from './Link';
import Price from './Price';

const ProductListItem = ({ product }) => {
    const { locale } = useIntl();

    const framed_price = product.price_with_frame > 0;
    const product_link = `/${locale}/view/${product.product_id}/${product.title}`;

    return (
        <Container className="grid-item">
            <ImageLink to={product_link}>
                <img src={product.list_image_src} alt={product.title} />
            </ImageLink>
            <Info to={product_link}>
                {product.title}
                <Price product={product} frame={framed_price} />
            </Info>
        </Container>
    )
};

export default ProductListItem;

const Container = styled.div`
    width: calc(${props => props.landscape ? '50%' : '25%'} - 1rem);
    margin: 0.5rem;

    @media (max-width: 1000px) {
        width: calc(100% - 1rem);
    }
`;

const ImageLink = styled(Link)`
    overflow: hidden;
    display: block;

    img {
        width: 100%;
        transition: transform .5s ease;

        &:hover {
            transform: scale(1.75);
        }
    }
`;

const Info = styled(Link)`
    display: block;
    padding-top: 3px;
    text-transform: uppercase;
    letter-spacing: 2px;

    ${Price} {
        float: right;
        color: #555;
        font-weight: 600;
    }
`;
