import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import ReactTooltip from "react-tooltip";
import { 
    FiChevronLeft,
    FiCheckCircle, 
    FiInfo 
} from 'react-icons/fi';

import Seo from '../helpers/Seo';
import { AppLanguageId } from '../helpers/languages';
import { useLocalStorage } from '../helpers/useBrowserStorage';
import api from '../helpers/request';
import CartContext from '../helpers/CartContext';
import PageTitle from '../components/PageTitle';
import { Checkbox, Label } from '../components/Forms';
import Button from '../components/Button';
import Spinner from '../components/Spinner';
import { Link } from '../components/Link';
import { Input, Textarea, SectionTitle, SectionRow, Required } from '../components/Forms';
import PageContent from '../components/PageContent';
import CartProductItem from '../components/CartProductItem';
import CartTotal from '../components/CartTotal';

const Cart = () => {
    const intl = useIntl();
    let history = useHistory();
    let cartState = useContext(CartContext);

    let [loading, setLoading] = useState(false);
    let [errors, setErrors] = useState({});

    const [cart, setCart] = useLocalStorage('cart', {
        name: "",
        email: "",
        phone: "",
        country: intl.locale === 'bg' ? "България" : 'Bulgaria',
        city: "",
        zip: "",
        address: "",
        delivery_option: intl.locale === 'bg' ? 'office' : 'home',
        comment: "",
        invoice: false,
        invoice_company: "",
        invoice_uin: "",
        invoice_recipient: "",
        invoice_country: "",
        invoice_city: "",
        invoice_address: "",
        invoice_zip: "",
    });

    const handleQtyChange = (aProduct, qty) => {
        aProduct.qty = qty;

        if(aProduct.qty === 0) {
            cartState.products = cartState.products.filter(product => product.product_id !== aProduct.product_id);
        }

        cartState.updateProducts([...cartState.products]);
    };

    let total = 0.0;

    cartState.products.forEach(product => {
        const price = product.framed ? product.price_with_frame : product.price;
        total += (price - (price * (product.discount / 100))) * product.qty;
    });

    const onPlaceOrder = () => {
        cart.language_id = AppLanguageId[intl.locale];
        cart.products = cartState.products.map((p) => ({ id: p.product_id, qty: p.qty, framed: p.framed }));

        setLoading(true);

        api({ route: '/api/orders/new', payload: cart })
            .then(res => {
                setLoading(false);

                if(res.status.code !== 0) {
                    setErrors(res.errors || {});
                } else {
                    cartState.updateProducts([]);
                    history.push(`/${intl.locale}/cart/success/${res.order_id}`);
                }
                
            })
            .catch((e) => {
                setLoading(false);
                console.error(e);
            });
    };

    return (
        <>
            <Seo title={intl.formatMessage({id: 'cart.title'})} />
            <PageTitle>
                <FormattedMessage id="cart.title" />
            </PageTitle>
            <PageContent width="1200px">
                <Button link onClick={() => history.goBack()}><FiChevronLeft /> <FormattedMessage id="cart.back_to_shop" /></Button>
                <OrderWrap>
                    <OrderForm>
                        <SectionTitle data-tip={intl.formatMessage({id: 'common.required'})} data-place="left">
                            <FormattedMessage id="common.contact" />
                            <Required>*</Required>
                        </SectionTitle>
                        <SectionRow>
                            <Input
                                key="name"
                                name="name"
                                grow="1"
                                placeholder={intl.formatMessage({id: 'common.name'})}
                                autocomplete="name"
                                value={cart.name}
                                onChange={(e) => setCart({...cart, name: e.target.value})}
                                error={errors.name} />
                        </SectionRow>
                        <SectionRow>
                            <Input
                                name="email"
                                type="email"
                                grow="1"
                                placeholder={intl.formatMessage({id: 'common.email'})}
                                autocomplete="email"
                                value={cart.email}
                                onChange={(e) => setCart({...cart, email: e.target.value})}
                                error={errors.email} />
                            <Input 
                                name="phone"
                                grow="1"
                                placeholder={intl.formatMessage({id: 'common.phone'})}
                                autocomplete="tel"
                                value={cart.phone}
                                onChange={(e) => setCart({...cart, phone: e.target.value})}
                                error={errors.phone} />
                        </SectionRow>
                        <SectionTitle data-tip={intl.formatMessage({id: 'common.required'})} data-place="left">
                            <FormattedMessage id="cart.shipping" />
                            <Required>*</Required>
                        </SectionTitle>
                        <SectionRow>
                            <Input
                                name="address_1"
                                grow="1"
                                placeholder={intl.formatMessage({id: 'common.address'})}
                                autocomplete="street-address"
                                value={cart.address} 
                                onChange={(e) => setCart({...cart, address: e.target.value})}
                                error={errors.address} />
                        </SectionRow>
                        <SectionRow>
                            <Input
                                name="country"
                                grow="2"
                                placeholder={intl.formatMessage({id: 'cart.shipping.country.placeholder'})}
                                autocomplete="country-name"
                                value={cart.country}
                                onChange={(e) => setCart({...cart, country: e.target.value})}
                                error={errors.country} />
                            <Input
                                name="city"
                                grow="2"
                                placeholder={intl.formatMessage({id: 'cart.shipping.city.placeholder'})}
                                autocomplete="address-level2"
                                value={cart.city} 
                                onChange={(e) => setCart({...cart, city: e.target.value})}
                                error={errors.city} />
                            <Input
                                name="zip_code"
                                grow="1"
                                placeholder={intl.formatMessage({id: 'cart.shipping.zip.placeholder'})}
                                autocomplete="postal-code"
                                value={cart.zip}
                                onChange={(e) => setCart({...cart, zip: e.target.value})}
                                error={errors.zip_code} />
                        </SectionRow>
                        {intl.locale === 'bg' ?
                            <OrderFormRow>
                                <Label>
                                    <Checkbox checked={cart.delivery_option === 'office'} onChange={(e) => setCart({...cart, delivery_option: 'office'})} />
                                    <FormattedMessage id="cart.deliver_office" />
                                </Label>
                                <Label>
                                    <Checkbox checked={cart.delivery_option === 'home'} onChange={(e) => setCart({...cart, delivery_option: 'home'})} />
                                    <FormattedMessage id="cart.deliver_home" />
                                </Label>
                            </OrderFormRow>
                        : null}
                        <SectionTitle>
                            <FormattedMessage id="cart.order_note" />
                        </SectionTitle>
                        <SectionRow>
                            <Textarea 
                                grow="1"
                                placeholder={intl.formatMessage({id: 'cart.order_note_placeholder'})}
                                rows="4" 
                                value={cart.comment} 
                                onChange={(e) => setCart({...cart, comment: e.target.value})} ></Textarea>
                        </SectionRow>
                        <SectionTitle>
                            <Label>
                                <Checkbox checked={cart.invoice} onChange={(e) => setCart({...cart, invoice: e.target.checked})} />
                                <FormattedMessage id="cart.invoice" />
                            </Label>
                        </SectionTitle>

                        {cart.invoice ? (
                            <>
                                <SectionRow>
                                    <Input
                                        name="company_name"
                                        grow="1"
                                        placeholder={intl.formatMessage({id: 'cart.invoice.company_name'})}
                                        value={cart.invoice_company}
                                        onChange={(e) => setCart({...cart, invoice_company: e.target.value})} />
                                    <Input
                                        name="vat_number"
                                        grow="1"
                                        placeholder={intl.formatMessage({id: 'cart.invoice.vat_number'})}
                                        value={cart.invoice_uin}
                                        onChange={(e) => setCart({...cart, invoice_uin: e.target.value})} />
                                </SectionRow>
                                <SectionRow>
                                    <Input
                                        name="recipient"
                                        grow="1"
                                        placeholder={intl.formatMessage({id: 'cart.invoice.recipient'})}
                                        value={cart.invoice_recipient}
                                        onChange={(e) => setCart({...cart, invoice_recipient: e.target.value})} />
                                </SectionRow>
                                <SectionRow>
                                    <Input
                                        name="invoice_address"
                                        grow="1"
                                        placeholder={intl.formatMessage({id: 'cart.invoice.address'})}
                                        value={cart.invoice_address}
                                        onChange={(e) => setCart({...cart, invoice_address: e.target.value})} />
                                </SectionRow>
                                <SectionRow>
                                    <Input
                                        name="invoice_country"
                                        grow="2"
                                        placeholder={intl.formatMessage({id: 'cart.shipping.country.placeholder'})}
                                        autocomplete="country-name"
                                        value={cart.invoice_country}
                                        onChange={(e) => setCart({...cart, invoice_country: e.target.value})} />
                                    <Input
                                        name="invoice_city"
                                        grow="2"
                                        placeholder={intl.formatMessage({id: 'cart.shipping.city.placeholder'})}
                                        autocomplete="address-level2"
                                        value={cart.invoice_city} 
                                        onChange={(e) => setCart({...cart, invoice_city: e.target.value})} />
                                    <Input
                                        name="invoice_zip"
                                        grow="1"
                                        placeholder={intl.formatMessage({id: 'cart.shipping.zip.placeholder'})}
                                        autocomplete="postal-code"
                                        value={cart.invoice_zip}
                                        onChange={(e) => setCart({...cart, invoice_zip: e.target.value})} />
                                </SectionRow>
                            </>
                        ) : null}

                        <HelpMsg><FiInfo /> <FormattedMessage id="cart.free_delivery" /></HelpMsg>
                        <HelpMsg><FiCheckCircle /> <FormattedMessage id="cart.carrier" /></HelpMsg>
                        <HelpMsg><FiCheckCircle /> <FormattedMessage id="cart.payment" /></HelpMsg>
                        {intl.locale === 'bg' ? <HelpMsg><FiCheckCircle /> <FormattedMessage id="cart.open_on_delivery" /></HelpMsg> : null}
                        <HelpMsg>
                            <FiCheckCircle / > <FormattedMessage id="cart.help_number" values={{
                                b: (...chunks) => <strong>{chunks}</strong>,
                            }} />
                        </HelpMsg>
                        
                        <Button 
                            primary
                            fullWidth
                            onClick={() => onPlaceOrder()}
                            disabled={cartState.products.length === 0}
                            data-tip={intl.formatMessage({id: cartState.products.length === 0 ? 'cart.no_products' : 'cart.place_order'})}>
                            <FormattedMessage id="cart.order_btn" />
                            {loading ? (<Spinner nopad />) : null}
                        </Button>
                    </OrderForm>
                    <OrderProducts>
                        {cartState.products.length === 0 
                            ? (<CartMessage><FormattedMessage id="cart.no_products" /></CartMessage>) 
                            : (<>
                                    <SectionTitle>
                                        <FormattedMessage id="cart.product" />
                                        <span><FormattedMessage id="cart.price" /></span>
                                    </SectionTitle>

                                    {cartState.products.map(product => (
                                        <CartProductItem product={product} onQtyChange={val => handleQtyChange(product, val)} key={product.product_id} />
                                    ))}
                                    
                                    <CartTotal
                                        price={`${total}лв.`}
                                        label={intl.formatMessage({id: 'cart.subtotal'})}
                                        small />
                                    
                                    {total < 100.0 
                                        ? <CartTotal 
                                            price="" 
                                            label={intl.formatMessage({id: 'cart.delivery'})}
                                            tooltip={intl.formatMessage({id: 'cart.weight_message'})}
                                            small />
                                        : <CartTotal 
                                            price={intl.formatMessage({id: 'cart.free'})}
                                            label={intl.formatMessage({id: 'cart.delivery'})}
                                            small />
                                    }

                                    <CartTotal price={`${total}лв.`} label={intl.formatMessage({id: 'cart.total'})} />
                                </>)
                        }
                    </OrderProducts>
                </OrderWrap>
                <OrderInfo>
                    <HelpMsg>
                        <FormattedMessage id="cart.footer.need_help" />
                        <Link to={`/${intl.locale}/contact-us`} primary="true">
                            <FormattedMessage id="cart.footer.contact_us" />
                        </Link>
                    </HelpMsg>
                    <HelpMsg>
                        <FormattedMessage id="cart.footer.legal1" />
                        <Link to={`/${intl.locale}/privacy`} primary="true">
                            <FormattedMessage id="privacy.title" />
                        </Link>
                        <FormattedMessage id="common.and" />
                        <Link to={`/${intl.locale}/terms`} primary="true">
                            <FormattedMessage id="terms.title" />
                        </Link>.
                        <FormattedMessage id="cart.footer.legal2" />
                    </HelpMsg>
                </OrderInfo>
            </PageContent>
            <ReactTooltip effect="solid" html />
        </>
    )
};

export default Cart;

const HelpMsg = styled.span`
    color: gray;
    padding: 5px 0;
    text-align: center;
`;

const OrderWrap = styled.div`
    display: flex;
    margin-top: 1rem;
    padding: 20px;
    background-color: white;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

    ${HelpMsg} {
        text-align: left;
        font-size: 0.9rem;
        padding: 0 0 10px 0;
    }

    @media (max-width: 420px) {
        flex-direction: column;
    }
`;

const OrderForm = styled.div`
    width: 50%;
    min-width: 50%;
    padding-right: 20px;
    margin-right: 20px;
    align-self: center;
    display: flex;
    flex-direction: column;

    @media (max-width: 420px) {
        width: calc(100% - 1rem);
        min-width: calc(100% - 1rem);
        margin: 0.5rem;
        padding: 0;
        order: 2;
    }
`;

const OrderProducts = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    @media (max-width: 420px) {
        width: calc(100% - 1rem);
        min-width: calc(100% - 1rem);
        margin: 0.5rem;
        padding: 0;
    }
`;

const OrderFormRow = styled.div`
    margin-bottom: 1.0rem;
`;

const CartMessage = styled.h3`
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: ${props => props.theme.main};
`;

const OrderInfo = styled.div`
    margin-top: 40px;
    display: flex;
    flex-direction: column;
`;
