
export const en = {
    'site_name': 'ART REVIEW',
    'site_title': 'ART REVIEW - Paintings, Posters, Icons',
    'keywords': 'Paintings, Icons, Collages, Posters, Presents, Ivan Todorov, Rossana Todorova, Art Gallery',
    'description': 'ART REVIEW is a family run online artwork shop where you can buy original paintings, posters, icons and more.',
    
    'common.and': ' and ',
    'common.address': 'Address',
    'common.phone': 'Phone',
    'common.email': 'Email',
    'common.contact': 'Contact',
    'common.name': 'Full name',
    'common.back-to-store': 'Back to store',
    'common.all-rights-reserved': 'All rights reserved',
    'common.back': 'Back',
    'common.loading': 'Loading',

    'home.title': 'Our picks',

    'cart.title': 'Cart',
    'cart.back_to_shop': 'Continue shopping',
    'cart.shipping': 'Shipping',
    'cart.shipping.country.placeholder': 'Country',
    'cart.shipping.city.placeholder': 'City',
    'cart.shipping.zip.placeholder': 'Postal code',
    'cart.deliver_office': 'Deliver to the nearest office',
    'cart.deliver_home': 'Deliver to my home address',
    'cart.order_note': 'Order note',
    'cart.order_note_placeholder': 'How can we help you',
    'cart.invoice': 'Invoice',
    'cart.invoice.company_name': 'Company name',
    'cart.invoice.vat_number': 'TAX (VAT) Number',
    'cart.invoice.recipient': 'Recipient',
    'cart.invoice.address': 'Billing address',
    'cart.free_delivery': 'Free delivery for orders over 150 BGN.',
    'cart.carrier': 'Your order will be shipped via DHL.',
    'cart.payment': 'Payment is requested upon delivery.',
    'cart.open_on_delivery': '',
    'cart.help_number': 'If you have any questions, call <b>+359 898 540 676</b>',
    'cart.no_products': 'No products in cart',
    'cart.place_order': 'Place order',
    'cart.order_btn': 'Order',
    'cart.product': 'Product',
    'cart.price': 'Price',
    'cart.remove': 'remove',
    'cart.framed': 'Framed',
    'cart.no_frame': 'Unframed',
    'cart.subtotal': 'Subtotal',
    'cart.delivery': 'Delivery',
    'cart.free': 'Free',
    'cart.total': 'Total',
    'cart.weight_message': 'The weight of your order is not known at the moment.<br />We will contact you with shipping information.',
    'cart.footer.need_help': 'Need help? Call +359 898 540 676 or +359 888 715 906 or',
    'cart.footer.contact_us': 'contact us.',
    'cart.footer.legal1': 'By placing your order you agree to Art Review ',
    'cart.footer.legal2': 'You also consent to some of your data being stored by Art Review for the purpose of fulfilling your order.',
    'cart.items_in_cart': 'item(s) in cart',

    'cart-success.title': 'Order placed',
    'cart-success.subtitle': 'Your order has been placed.',
    'cart-success.order_number': 'Order number',
    'cart-success.copy1': 'We will send you an email shortly with the details of your order. If you have any questions about the products you ordered, don\'t hesitate to ',
    'cart-success.copy2': 'We will be happy to help. Thank you for choosing us!',

    'contacts.title': 'Contact us',
    'contacts.legal_name': 'Sole Trader REVIEW R.Todorova',
    'contacts.address': '12 Sredna Gora Str. Tsaratsovo, Plovdiv municipality, Bulgaria',
    'contacts.phone_or_email': 'Phone or email',
    'contacts.about': 'About',
    'contacts.product_number': 'Product # (optional)',
    'contacts.order_number': 'Order # (optional)',
    'contacts.message': 'Message',
    'contacts.send': 'Send',

    'contact-success.title': 'Thank you for getting in touch.',
    'contact-success.message': 'We will got back to you as soon as we can.',

    'search.title': 'Search',
    'search.input-placeholder': 'START TYPING',

    'product.price': 'Price',
    'product.with_frame': 'with frame',
    'product.without_frame': 'without frame',
    'product.size': 'Size',
    'product.cm': 'cm',
    'product.subject': 'Subject',
    'product.author': 'Author',
    'product.materials': 'Materials',
    'product.description': 'Description',
    'product.number': 'Product Number',
    'product.ready_to_ship': 'Ready to ship in',
    'product.two_tree_days': '2 - 3 business days',
    'product.business_days': 'business days',
    'product.unavailable': 'Currently unavailable',
    'product.buy': 'Buy now',
    'product.buy_poster': 'Buy as a poster',
    'product.information': 'For more information',
    'product.diff_size': 'Order in different size',
    'product.ask_question': 'Ask a question about this',
    'product.views': 'other people have viewed this artwork. Last one was',

    'filters.sort': 'Sort',
    'filters.price': 'Price',
    'filters.size': 'Size',
    'filters.subject': 'Subject',
    'filters.material': 'Material',
    'filters.orientation': 'Orientation',
    'filters.recently_added': 'Recently added',
    'filters.price_low_to_high': 'Price: low to high',
    'filters.price_high_to_low': 'Price: high to low',
    'filters.under_20': 'under 20лв.',
    'filters.20_to_60': '20лв. - 60лв.',
    'filters.60_to_100': '60лв. - 100лв.',
    'filters.over_100': 'over 100лв.',
    'filters.small': 'Small',
    'filters.medium': 'Medium',
    'filters.large': 'Large',
    'filters.horizontal': 'Horizontal',
    'filters.vertical': 'Vertical',
    'filters.square': 'Square',
    'filters.no_products': 'No products found',

    'about.title': 'Who we are',
    'about.ivan_name': 'Ivan Todorov',
    'about.rosi_name': 'Rossana Todorova',

    'terms.title': 'Terms of Use',
    'privacy.title': 'Privacy Policy',
    'copyright.title': 'Copyright Policy',
};

export const bg = {
    'site_name': 'АРТ РЕВЮ',
    'site_title': 'АРТ РЕВЮ - Картини, Постери, Икони',
    'keywords': 'Картини, Икони, Колажи, Постери, Подаръци, Иван Тодоров, Росана Тодорова, Галерия',
    'description': 'АРТ РЕВЮ е онлайн галерия където можете да закупите оригинални, ръчно изработени картини, постери, колажи и икони.',

    'common.and': ' и ',
    'common.address': 'Адрес',
    'common.phone': 'Телефон',
    'common.email': 'Имейл',
    'common.contact': 'Лице за контакт',
    'common.name': 'Име и фамилия',
    'common.required': 'Задължително поле',
    'common.back-to-store': 'Обратно в магазина',
    'common.all-rights-reserved': 'Всички права запазени',
    'common.back': 'Назад',
    'common.loading': 'Зарежда се',

    'home.title': 'Избрани предложения',

    'cart.title': 'Количка',
    'cart.back_to_shop': 'Разгледайте още',
    'cart.shipping': 'Доставка',
    'cart.shipping.country.placeholder': 'Държава',
    'cart.shipping.city.placeholder': 'Населено място',
    'cart.shipping.zip.placeholder': 'Пощ. код',
    'cart.deliver_office': 'Доставка до офис на Еконт',
    'cart.deliver_home': 'Доставка до домашен адрес',
    'cart.order_note': 'Бележка',
    'cart.order_note_placeholder': 'Как можем да Ви помогнем?',
    'cart.invoice': 'Фактура',
    'cart.invoice.company_name': 'Име на фирма',
    'cart.invoice.vat_number': 'ЕИК',
    'cart.invoice.recipient': 'МОЛ',
    'cart.invoice.address': 'Адрес за фактуриране',
    'cart.free_delivery': 'Безплатна доставка за поръчки над 100лв.',
    'cart.carrier': 'Вашата поръчка ще бъде изпратена по куриерска фирма Еконт.',
    'cart.payment': 'Плащането се извършва при доставка.',
    'cart.open_on_delivery': 'Ще имате възможност да отворите и прегледате поръчаните от Вас продукти преди да ги заплатите.',
    'cart.help_number': 'Ако имате въпроси, обадете се на <b>+359 898 540 676</b> или <b>+359 888 715 906</b>',
    'cart.no_products': 'Няма добавени продукти в количката',
    'cart.place_order': 'Изпратете поръчката',
    'cart.order_btn': 'Поръчка',
    'cart.product': 'Продукт',
    'cart.price': 'Цена',
    'cart.remove': 'премахни',
    'cart.framed': 'С рамка',
    'cart.no_frame': 'Без рамка',
    'cart.subtotal': 'Междинна сума',
    'cart.delivery': 'Доставка',
    'cart.free': 'Безплатна',
    'cart.total': 'Общо',
    'cart.weight_message': 'Теглото на вашата поръчка е неизвестно в момента.<br />Ще се свържем допълнително с вас за цена на доставка.',
    'cart.footer.need_help': 'Ако имате въпроси, позвънете на +359 898 540 676 или +359 888 715 906 или се ',
    'cart.footer.contact_us': 'свържете с нас.',
    'cart.footer.legal1': 'Поръчвайки от Art Review Вие се съласявате с нашата ',
    'cart.footer.legal2': 'Вие също се съгласявате Art Review да съхранява част от Вашите данни с цел изпълнението на поръчки и услиги.',
    'cart.items_in_cart': 'продукт(а) в количката',

    'cart-success.title': 'Успешна поръчка',
    'cart-success.subtitle': 'Вашата поръчка е приета',
    'cart-success.order_number': 'Номер',
    'cart-success.copy1': 'Ще получите имейл email с информация ва Вашата поръчка. Ако имате въпроси, не се колебайте да се ',
    'cart-success.copy2': ' Ще се радваме да Ви помогнем. Благодарим Ви, че избрахте Арт Ревю.',

    'contacts.title': 'Контакти',
    'contacts.legal_name': 'ЕТ Ревю Р.Тодорова',
    'contacts.address': 'ул. Средна Гора 12, с. Царацово, обл. Пловдив, България',
    'contacts.phone_or_email': 'Телефон или имейл',
    'contacts.about': 'Относно',
    'contacts.product_number': 'Номер на продукт (незадължително)',
    'contacts.order_number': 'Номер на поръчка (незадължително)',
    'contacts.message': 'Съобщение',
    'contacts.send': 'Изпрати',

    'contact-success.title': 'Благодарим Ви, че се свързахте с нас.',
    'contact-success.message': 'Ще се постараем да Ви отговорим съзможно най-скоро.',

    'search.title': 'Търсене',
    'search.input-placeholder': 'ТЪРСЕНЕ',

    'product.price': 'Цена',
    'product.with_frame': 'с рамка',
    'product.without_frame': 'без рамка',
    'product.size': 'Размер',
    'product.cm': 'см',
    'product.subject': 'Тема',
    'product.author': 'Автор',
    'product.materials': 'Материали',
    'product.description': 'Описание',
    'product.number': 'Номер',
    'product.ready_to_ship': 'Може да бъде изпратен до',
    'product.two_tree_days': '2 - 3 работни дни',
    'product.business_days': 'работни дни',
    'product.unavailable': 'Не е наличен',
    'product.buy': 'Поръчай сега',
    'product.buy_poster': 'Поръчай постер',
    'product.information': 'За повече информация',
    'product.diff_size': 'Поръчай в друг размер',
    'product.ask_question': 'Задайте въпрос за този продукт',
    'product.views': 'човека са разглеждали този продукт. Последния е бил',

    'filters.sort': 'Сортиране',
    'filters.price': 'Цена',
    'filters.size': 'Размер',
    'filters.subject': 'Тема',
    'filters.material': 'Материал',
    'filters.orientation': 'Ориентация',
    'filters.recently_added': 'Последно добавени',
    'filters.price_low_to_high': 'Цена: от ниска към висока',
    'filters.price_high_to_low': 'Цена: от висока към ниска',
    'filters.under_20': 'под 20лв.',
    'filters.20_to_60': '20лв. - 60лв.',
    'filters.60_to_100': '60лв. - 100лв.',
    'filters.over_100': 'над 100лв.',
    'filters.small': 'Малък',
    'filters.medium': 'Среден',
    'filters.large': 'Голям',
    'filters.horizontal': 'Хоризонтална',
    'filters.vertical': 'Вертикална',
    'filters.square': 'Квадратна',
    'filters.no_products': 'Няма намерени продукти',

    'about.title': 'За нас',
    'about.ivan_name': 'Иван Тодоров',
    'about.rosi_name': 'Росана Тодорова',

    'terms.title': 'Условия за Ползване',
    'privacy.title': 'Политика за поверителност',
    'copyright.title': 'Авторско право',
};

export const AppStrings = {
    bg: bg,
    en: en
};

export default AppStrings;