import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FiLoader } from 'react-icons/fi';

const Spinner = ({...props}) => (
    <Inner {...props}><Icon /></Inner>
);

export default Spinner;

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;

const Icon = styled(FiLoader)`
    animation: ${rotate} 2s infinite linear;
`;

const Inner = styled.div`
    display: inline-block;
    padding: ${props => props.nopad ? '0.1' : '1'}rem;
`;
